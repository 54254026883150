import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private contentTypeSubject = new BehaviorSubject<string[]>([]);
  private specialitySubject = new BehaviorSubject<string[]>([]);
  private categorySubject = new BehaviorSubject<string[]>([]);

  contentType$ = this.contentTypeSubject.asObservable();
  speciality$ = this.specialitySubject.asObservable();
  category$ = this.categorySubject.asObservable();

  setContentType(filters: string[]) {
    this.contentTypeSubject.next(filters);
  }

  setSpeciality(filters: string[]) {
    this.specialitySubject.next(filters);
  }

  setCategory(filters: string[]) {
    this.categorySubject.next(filters);
  }
}
