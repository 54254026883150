// api.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { env } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private themsettingsSubject = new BehaviorSubject<any>('');
  private journalIdSubject = new BehaviorSubject<string>('');
  public journalId: string = '';
  constructor(private http: HttpClient) { }
  setAllThemsettingsData(themsettingsData: any) {
    this.themsettingsSubject.next(themsettingsData);
  }
  getAllThemsettingsData() {
    return this.themsettingsSubject.asObservable();

  }

  setJournalId(journalId: string) {
    // this.journalIdSubject.next(journalId);
    this.journalId = journalId;
  }

  getJournalId() {
    // return this.journalIdSubject.asObservable();
    return this.journalId;
  }

}
