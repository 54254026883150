<div class="row">
    <div class="col-12 mt-2">
        <div class="d-flex justify-content-center" *ngIf="!pageloading ;else PageNotLoaded">
            <div class="advertisement-box m-2" *ngIf="pageType=='homepage'">
                <h3 class="advertisement text-center mb-0">Leaderboard
                </h3>
                <p class="text-center advertisement-content ">728 x 90</p>
            </div>
            <div class="advertisement-box m-2 board-size" *ngIf=" pageType=='listpage'">
                <h3 class="advertisement text-center mb-0">Super Leaderboard
                </h3>
                <p class="text-center advertisement-content ">990 x 90</p>
            </div>
        </div>
        <ng-template #PageNotLoaded>
            <div class="d-flex justify-content-center">
                <div class="advertisement-box m-2 skeleton">
                    <!-- <h3 class="advertisement text-center mb-0">Leaderboard
                </h3>
                <p class="text-center advertisement-content ">728 x 90</p> -->
                </div>
            </div>
        </ng-template>

    </div>
</div>