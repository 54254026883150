import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Output, ViewChild, EventEmitter, AfterViewInit, OnInit, ChangeDetectorRef, HostListener, Inject, PLATFORM_ID, Input } from '@angular/core';
@Component({
  selector: 'app-billboard',
  templateUrl: './billboard.component.html',
  styleUrls: ['./billboard.component.scss'],
})
export class BillboardComponent implements OnInit, AfterViewInit {
  @Output() BillboardHeight = new EventEmitter<number>();
  @ViewChild('billboardBoxDiv') billboardBoxDiv: ElementRef = null;
  billBoardWidth = 100;
  screenWidth = 0;
  paddingLength = 0;
  @Input() pageloading: boolean;
  isModalOpen = false;
  constructor(private cdr: ChangeDetectorRef, @Inject(PLATFORM_ID) private platformId: any) { }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = event.target.window.innerWidth;
      this.getBillboardBoxHeight();
    }

  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
    }
    this.getBillboardBoxHeight();

  }


  ngAfterViewInit(): void {
    if (this.screenWidth < 1800) this.getBillboardBoxHeight();
    this.cdr.detectChanges(); // Mark for change detection
  }


  getBillboardBoxHeight(): void {
    const height = this.billboardBoxDiv?.nativeElement.offsetHeight;
    this.BillboardHeight.emit(height - 190);
  }
  openJoinModal() {
    this.isModalOpen = true;
  }

  closeJoinModal() {
    this.isModalOpen = false;
  }
}
