<div *ngIf="pageType === 'homepage' && !pageloading; else noCategoryTilesLoaded">
  <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist">
    <button *ngFor="let eachCategory of categoryTitles; let ind = index"
      [ngClass]="ind === 0 ? 'nav-link active' : 'nav-link ps-4'" id="nav-{{eachCategory}}-tab" 
      data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all" 
      aria-selected="true" (click)="selectedCategory(eachCategory)">
      {{eachCategory}} 
    </button>
  </div>
  <div class="tab-content p-2 pt-1 bg-light" id="nav-tabContent">
    <div *ngFor="let eachCategory of categoryTitles; let ind = index">
      <div *ngIf="selectedCatheading === eachCategory" class="tab-pane fade active show" id="nav-all" 
        role="tabpanel" [attr.aria-labelledby]="'nav-' + eachCategory + '-tab'">
        <app-search [pageType]="pageType" [category]="selectedCat" (searchEvent)="onSearchEvent($event)">
        </app-search>
        <app-leader-board [category]="eachCategory" [pageType]="pageType"></app-leader-board>
      </div>
    </div>
  </div>
</div>

<ng-template #noCategoryTilesLoaded>
  <div *ngIf="pageType === 'listpage' && !pageloading; else noCategoryContentLoaded">
    <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist"
      [ngStyle]="{ 'height': (categoryContent.length > 1 && screenWidth < 768) ? '10rem' : '' }">
      <button *ngFor="let eachCategory of categoryContent; let ind = index"
        [ngClass]="ind === 0 ? 'nav-link active' : 'nav-link ps-4'" id="nav-{{eachCategory.heading}}-tab"
        data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all"
        aria-selected="true" (click)="selectedCategory(eachCategory.heading)">
        {{eachCategory.heading}}
      </button>
    </div>
    <div class="tab-content p-2 pt-1 bg-light" id="nav-tabContent">
      <div *ngFor="let eachCategory of categoryContent; let ind = index">
        <div class="tab-pane fade active show" id="nav-all" role="tabpanel"
          [attr.aria-labelledby]="'nav-' + eachCategory.heading + '-tab'">
          <app-search [pageType]="pageType" [category]="selectedCat" (searchEvent)="onSearchEvent($event)">
          </app-search>
          <app-leader-board [category]="eachCategory.heading" [pageType]="pageType"></app-leader-board>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noCategoryContentLoaded>
  <div *ngIf="pageType === 'allpage' && !pageloading; else noContentsLoaded">
    <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist"
      [ngStyle]="{ 'height': (categoryArray.length && screenWidth < 768) ? '10rem' : '' }">
      <button *ngFor="let eachCategory of categoryArray; let ind = index"
        [ngClass]="ind === 0 ? 'nav-link active' : 'nav-link ps-4'" id="nav-{{eachCategory}}-tab"
        data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all"
        aria-selected="true" (click)="selectedCategory(eachCategory)">
        {{eachCategory}} 
      </button>
    </div>
    <div class="tab-content p-2 pt-1 bg-light" id="nav-tabContent">
      <div *ngFor="let eachCategory of categoryArray; let ind = index">
        <div *ngIf="selectedCatheading === eachCategory" class="tab-pane fade active show" id="nav-all"
          role="tabpanel" [attr.aria-labelledby]="'nav-' + eachCategory + '-tab'">
          <app-search [pageType]="pageType" [category]="selectedCat" (searchEvent)="onSearchEvent($event)">
          </app-search>
          <app-leader-board [category]="eachCategory" [pageType]="pageType"></app-leader-board>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noContentsLoaded>
  <div *ngIf="pageType !=='academy'">
    <div class="nav nav-tabs mb-3" id="nav-tab" role="tablist"
      [ngStyle]="{ 'height': (tabarray.length > 1 && screenWidth < 768) ? '10rem' : '' }">
      <button *ngFor="let eacharray of tabarray" class="nav-link ps-4 skeleton skeleton-nav" id="nav-all-tab"
        data-bs-toggle="tab" data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all"
        aria-selected="true">
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      </button>
    </div>
    <div class="tab-content p-2 pt-1 bg-light" id="nav-tabContent">
      <div class="tab-pane fade active show" id="nav-all" role="tabpanel" [attr.aria-labelledby]="'nav-all-tab'">
        <app-search [category]=""></app-search>
        <app-leader-board [category]="" [pageloading]="pageloading" [pageType]="pageType"></app-leader-board>
      </div>
    </div>
  </div>
</ng-template>
