import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainHomeService } from '../../services/main-home.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Output() close = new EventEmitter<void>();
  countries: any[] = [];
  surgicalSpecialties: any[] = [];
  modalForm: FormGroup;
  submitted = false;
  private existingEmails: string[] = []; // Store existing emails
  success: boolean=false;;
  falied:boolean;
  constructor(private http: HttpClient, private fb: FormBuilder, private mainService: MainHomeService) {
    this.modalForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      socialMedia: [''],
      country: ['', Validators.required],
      surgicalSpecialty: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.http.get('https://restcountries.com/v3.1/all').subscribe((data: any) => {
      const sortedCountries = data.map((country: { cca2: any; name: { common: any; }; }) => ({
        countryid: country.cca2,
        name: country.name.common
      })).sort((a: { name: string; }, b: { name: string; }) => a.name.localeCompare(b.name));

      this.countries = [...sortedCountries];
    });

    this.getContentType();
  }

  onSubmit() {
    this.submitted = true;

    if (this.modalForm.valid) {
      const formData = this.modalForm.value;

      this.mainService.addBjsJoinAcademy(formData).subscribe(
        (response: any) => {
          if (response.status === 200) {
            this.success=true;
          //  setTimeout(() => {
          //   this.modalForm.reset();
          //   this.closeModal();
          //  }, 2000);
          } else if (response.status === 400 && response.message === 'Email already taken') {
            this.falied=true
            this.modalForm.get('email')?.setErrors({ emailTaken: true });
          }
        },
        (error) => {

        }
      );
    } else {

    }
  }

  closeModal() {
    this.close.emit();
  }

  getContentType() {
    this.mainService.getContentType().subscribe((response: any) => {
      this.surgicalSpecialties = response[0].values;
    });
  }



}
