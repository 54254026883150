<div id="billboard" class="container-fluid" >
    <div #billboardBoxDiv class="billboard"  >
        <div class="billboard-box" *ngIf="!pageloading ;else PageNotLoaded" >
                <h3 class="advertisement text-center mt-4 pt-4 mb-0">Billboard
                </h3>
                <p class="text-center measure ">970 x 250</p>
           
        </div>
        <ng-template #PageNotLoaded>
            <div class="billboard-box skeleton" >
                   
               
            </div>
        </ng-template>
        <div class="BJS-login card">
            <div class="card-body p-0">
                <div class="d-flex flex-column h-100">
                    <div class="join-background p-3">
                        <h1 class="text-center  mb-0 pt-2">JOIN</h1>
                        <h5 class="text-center mb-0 pb-2 ">BJS Academy</h5>
                    </div>
                    <div class="d-flex justify-content-center w-100 position-absolute ">
                        <span class="circle-arrow" (click)="openJoinModal()">
                            <fa-icon [icon]="['fas','right-long']"></fa-icon>
                        </span>

                    </div>
                    <div class="h-50 p-3">
                        <p class="text-left join-text ">Join BJS Academy And
                            Receive Our Latest News
                            And Updates.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-modal *ngIf="isModalOpen" (close)="closeJoinModal()"></app-modal>