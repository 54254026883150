<div class="col" #maindiv *ngIf="!pageloading;else PageNotLoaded">


    <div class="card article-card">
        <img *ngIf="article.article_cover_image" [src]="coverImageUrl" (click)="navigateToArticle(article.article_link,article.category_heading.id)"
            class="card-img-top article-img" [alt]="article.alt">
        <img *ngIf="article.article_cover_image==null" src="bjs_article_cover_image.png" 
            class="card-img-top article-img" [alt]="article.alt">

        <div class="card-body">
            <div class="ellipsis-div">

                <h2 class="card-title"(click)="navigateToArticle(article.article_link,article.category_heading.id)" title="{{article.article_title}}" [innerHTML]="safeTitle"></h2>
                <p *ngIf="article.authors && article.authors.length > 0" class="card-text">
                    <!-- Use helper methods to check the type of authors array -->
                    <span *ngIf="isStringArray(article.authors)">
                        {{ article.authors.join(', ') }}
                    </span>
                    <span *ngIf="isObjectArray(article.authors)">
                        <span *ngFor="let author of article.authors; let isLast = last">
                            {{ author.name }}<span *ngIf="!isLast">, </span>
                        </span>
                    </span>
                </p>

                <p class="card-content" [innerHTML]="safeSummary"></p>
            </div>
         
              <div class="d-flex flex-row align-items-center">
                <div class="card-tag" #content>
                    <a *ngFor="let tag of article.surgical_speciality"  class="tag-content">
                        <span [innerHTML]="highlightReference(tag)" (click)="filterClicked(tag, 'speciality', article.category_heading.id);highlightReference(tag)"></span>
                    </a>

                    <a *ngFor="let type of article.content_type"  class="tag-content">
                        <span [innerHTML]="highlightType(type)" (click)="filterClicked(type, 'type');highlightType(type)"></span>
                    </a>

                   

                    <!-- <a *ngIf="returnCategory(article.category_heading.id)" class="tag-content">
                        <span [innerHTML]="highlightReferenceHeading(article.category_heading.title)" 
                              (click)="categoryClick(article.category_heading.id, article.category_heading.title)">
                        </span>
                    </a> -->
                    <a *ngIf="returnCategory(article.category_heading.id)" class="tag-content">
                        <span [innerHTML]="highlightReferenceHeading(article.category_heading.title)"
                              (click)="categoryClick(article.category_heading.id, article.category_heading.title)">
                        </span>
                      </a>
                  
                </div>

                <div class="txtcol" #showMore (click)="toggleShowMore(maindiv,$event)" ><a>More...</a></div>
            </div>
            <!-- <div class="txtcol" (click)="toggleShowMore(maindiv,$event)"><a>Show more...</a>
            </div> -->
            <span class="card-date" *ngIf="article.published_online">
                <fa-icon [icon]="['fas','calendar-days']" class="icon-date"></fa-icon>{{ article.published_online }}
            </span>
            <span class="card-date" *ngIf="article.pub_date">
                <fa-icon [icon]="['fas','calendar-days']" class="icon-date"></fa-icon>{{ article.published_online }}
            </span>
        </div>
    </div>
</div>
<ng-template #PageNotLoaded>
    <div class="card  article-card">
              
        <div class="card-img-top skeleton skeleton-img" >

        </div>
        <div class="card-body">

            <h5 class="skeleton-card-title skeleton skeleton-text "></h5>
            <p class="skeleton-author skeleton skeleton-text skeleton-text-80"></p>
            <p class=" skeleton skeleton-text"></p>
            <p class=" skeleton skeleton-text"></p>
            <p class=" skeleton skeleton-text"></p>
            <p class=" skeleton skeleton-text skeleton-text-80 "></p>
            <div class="row show-less">
                <div class="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-10 ">
                    <div class="badges ml-2 overflow ">
                        <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                        </div>
                        &nbsp; 
                        <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        </div>

                    </div>

                </div>

            </div>


            <div class="pub-date mt-auto d-flex justify-content-end skeleton skeleton-date" tabindex="0">
                &nbsp; &nbsp;
            </div>
        </div>
    </div>
</ng-template>

<!-- <div class="col">
        <div class="card article-card">
            <img src="images/Breast-surgeon-1.jpg" class="card-img-top article-img" alt="BJS">
            <div class="card-body">
                <div class="ellipsis-div">
                    <h2 class="card-title">Why I became a breast surgeon</h2>
                    <p class="card-text">Malin Sund</p>
                    <p class="card-content">My name is Malin Sund. I work as professor of surgery and consultant
                        of
                        surgery at the University of Helsinki and Helsinki University Hospital in Finland, and
                        as
                        guest professor of surgery at Umeå University in Sweden. I was invited to write this
                        piece
                        about why I became a breast surgeon. The question is interesting since I came to realize
                        that perhaps this was more serendipity than an active pursuit. During surgical training,
                        I
                        found most clinical subspecialities interesting. I am also quite sure that if I had
                        chosen
                        another field of surgery, I would have been equally content.</p>
                </div>
                <div class="card-tag">
                    <a href="#" class="tag-content">Breast</a>
                </div>
                <span class="card-date"><i class="fa-solid fa-calendar-days icon-date"></i>06 May 2024</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card article-card">
            <img src="images/Young-BJS-Thumbnail.jpg" class="card-img-top article-img" alt="BJS">
            <div class="card-body">
                <div class="ellipsis-div">
                    <h2 class="card-title">Management of retrorectal tumours</h2>
                    <p class="card-text">Bilkhu AS, Wild J, Sagar PM.</p>
                    <p class="card-content">Access and approach to the tumour are dictated by the location and
                        morphology of the tumour. There are three main approaches: a perineal approach, an
                        abdominal
                        approach, and a combined approach. The choice of approach is determined by tumour size,
                        tumour position, suspicion of malignancy, and involvement of adjacent structures.</p>
                </div>
                <div class="card-tag">
                    <a href="#" class="tag-content">Colorectal</a>
                </div>
                <span class="card-date"><i class="fa-solid fa-calendar-days icon-date"></i>25 May 2024</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card article-card">
            <img src="images/Young-BJS-Thumbnail.jpg" class="card-img-top article-img" alt="BJS">
            <div class="card-body">
                <div class="ellipsis-div">
                    <h2 class="card-title">Changing perspectives in the treatment of colorectal liver metastases
                    </h2>
                    <p class="card-text">Kron P, Lodge PA</p>
                    <p class="card-content">Ensuring the FLR is adequate is an important consideration as a poor
                        decision can lead to significant postoperative morbidity and risk of mortality due to
                        liver failure. CT volumetry offers a degree of reassurance, but better functional tests
                        for FLR are needed.</p>
                </div>
                <div class="card-tag">
                    <a href="#" class="tag-content">Colorectal</a>
                </div>
                <span class="card-date"><i class="fa-solid fa-calendar-days icon-date"></i>04 March 2024</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card article-card">
            <img src="images/Young-BJS-Thumbnail.jpg" class="card-img-top article-img" alt="BJS">
            <div class="card-body">
                <div class="ellipsis-div">
                    <h2 class="card-title">Management of the open abdomen
                    </h2>
                    <p class="card-text">Soliman F, Carlson GL, McWhirter D.</p>
                    <p class="card-content"></p>
                </div>
                <div class="card-tag">
                    <a href="#" class="tag-content">General</a>
                    <a href="#" class="tag-content">Video</a>
                </div>
                <span class="card-date"><i class="fa-solid fa-calendar-days icon-date"></i>23 February
                    2024</span>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card article-card">
            <img src="images/Why-I-became-an-orthopaedic-surgeon-feature-image.jpg" class="card-img-top article-img"
                alt="BJS">
            <div class="card-body">
                <div class="ellipsis-div">
                    <h2 class="card-title">Changing perspectives in the treatment of colorectal liver metastases
                    </h2>
                    <p class="card-text">Kron P, Lodge PA</p>
                    <p class="card-content">Ensuring the FLR is adequate is an important consideration as a poor
                        decision can lead to significant postoperative morbidity and risk of mortality due to
                        liver failure. CT volumetry offers a degree of reassurance, but better functional tests
                        for FLR are needed.</p>
                </div>
                <div class="card-tag">
                    <a href="#" class="tag-content">Colorectal</a>
                </div>
                <span class="card-date"><i class="fa-solid fa-calendar-days icon-date"></i>04 March 2024</span>
            </div>
        </div>
    </div> -->