import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrl: './leader-board.component.scss'
})
export class LeaderBoardComponent {
@Input()category=''
@Input()pageloading:boolean;
@Input() pageType=''
constructor(){
}
}
