<ul class="pagination">
  <li class="page-item" [ngClass]="{'disabled': currentPage === 1}">
    <a class="page-link" (click)="goToPage(currentPage - 1)" [ngClass]="{'not-allowed': currentPage === 1}">Previous</a>
  </li>
  <li *ngFor="let page of pages" class="page-item" [ngClass]="{'active': currentPage === page}">
    <a *ngIf="page !== '...'" class="page-link" (click)="goToPage(page)" [ngClass]="{'pointer': currentPage !== page}">{{ page }}</a>
    <span class="pagination-dot" *ngIf="page === '...'">{{ page }}</span>
  </li>
  <li class="page-item" [ngClass]="{'disabled': currentPage === totalPages}">
    <a class="page-link" (click)="goToPage(currentPage + 1)" [ngClass]="{'not-allowed': currentPage === totalPages}">Next</a>
  </li>
</ul>
