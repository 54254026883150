import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-portrait',
  templateUrl: './portrait.component.html',
  styleUrl: './portrait.component.scss'
})
export class PortraitComponent {
@Input() pageloading:boolean;
constructor(){

}
}
