<div class="card advertisement-2 d-flex" *ngIf="!pageloading ;else PageNotLoaded">
    <h3 class="text-center">BJS Ad
    </h3>
    <p class="text-center ">300 x 675</p>
</div>
<ng-template #PageNotLoaded>
    <div class="card advertisement-2 d-flex skeleton" >
       
    </div>
</ng-template>
