<div class="card advertisement-1 d-flex" *ngIf="!pageloading else PageNotLoaded">
    <h3 class="text-center">Portrait
    </h3>
    <p class="text-center ">300 x 1050</p>
</div>
<ng-template #PageNotLoaded>
    <div class="card advertisement-1 d-flex skeleton" >
      
    </div>
</ng-template>
