import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bjs-ad',
  templateUrl: './bjs-ad.component.html',
  styleUrl: './bjs-ad.component.scss'
})
export class BjsAdComponent {
@Input()  pageloading:boolean;
constructor(){

}
}
