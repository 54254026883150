import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit, viewChild, ViewChild } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { ActivatedRoute } from '@angular/router';
import { CategoryComponent } from '../category/category.component';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss'
})
export class BannerComponent implements OnInit {
  @Input() pageType = '';
  @Input() BannerHeading = '';
  @Input() BannerImage = '';
  // @Input() articles: any;
  @Input() contentType: any;
  @Input() surgicalSpecialty: any;
  @Input() pageloading: boolean;

  BannerHeadingfirstPart = '';
  BannerHeadinglastPart = '';
  contentHeight = '';
  @Output() searchEvent = new EventEmitter<string>();


  //  CategoryarrayForListing = ['Young BJS'];
  categories: any[] = [];
  categoryTitles: string[] = [];
  category: string = '';
  categoryTitle: string[] = [];
  CategoryarrayForListing: string[] = [];
  categoryContent: any;
  contentloading = true;
  constructor(
    private articleService: ArticleService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params['subcategory']) {
        this.category = params['subcategory'];

      } else {
        this.category = params['category'];

      }
      this.getCategories();
      if (this.category) {
        this.getCategoryContent(this.category);
      }
    });
    this.splitText()

  }


  getCategories(): void {
    this.articleService.getAllCategories().subscribe((response: any) => {
      // Assuming "academy" is the identifier for the main "Academy" menu
      const academyMenu = response.find((menu: any) => menu.identifier === 'bjs-academy');

      if (academyMenu) {
        // Filter out "Scientific Surgery" submenu
        const filteredSubMenus = academyMenu.subMenus.filter((subMenu: any) => subMenu.identifier !== 'scientific-surgery');

        // Include "Scientific Surgery" submenus
        const scientificSurgeryMenu = academyMenu.subMenus.find((subMenu: any) => subMenu.identifier === 'scientific-surgery');
        if (scientificSurgeryMenu) {
          filteredSubMenus.push(...scientificSurgeryMenu.subMenus);
        }

        // Update categories and categoryTitles
        this.categories = filteredSubMenus;
        this.categoryTitles = ['All', ...this.categories.map((subMenu: any) => subMenu.menu_name)];
        //this.setCategoryTitle();
      }
    });
  }


  setCategoryTitle(): void {
    if (this.category && this.categories.length > 0) {
      const matchedCategory = this.categories.find(cat => cat.identifier === this.category);
      if (matchedCategory) {
        const subMenus = matchedCategory.subMenus.filter((subMenu: { identifier: string; }) => subMenu.identifier !== 'scientific-surgery');
        this.categoryTitle = [matchedCategory.menu_name];
        this.CategoryarrayForListing = subMenus.map((subMenu: { menu_name: any; }) => subMenu.menu_name);

      } else {
        this.categoryTitle = [];
        this.CategoryarrayForListing = [];
      }
    } else {
      this.categoryTitle = [];
      this.CategoryarrayForListing = [];
    }
  }

  getBackgroundStyle() {
    this.pageloading=false;
    return this.pageType === 'homepage' || this.pageType === 'academy' ? {
      'background-image': 'url("' + this.BannerImage + '")',
      'background-size': 'cover',
      'background-position': 'center',
      'background-position-y': this.pageType === 'academy' ? '1rem' : '0',
      'background-repeat': 'no-repeat',
      'height': '450px'
    } : {
      'height': 'auto'
    };
  }
  splitText() {
    const words = this.BannerHeading.split(' ');
    if (words.length > 1) {
      this.BannerHeadingfirstPart = words.slice(0, -1).join(' ');
      this.BannerHeadinglastPart = words[words.length - 1];
    } else {
      this.BannerHeadingfirstPart = this.BannerHeading;
      this.BannerHeadinglastPart = '';
    }


  }


  onSearchEvent(searchValue: string) {
    this.searchEvent.emit(searchValue);
  }
  getCategoryContent(category: string) {
    const data = {
      category: category
    }
    this.articleService.getCategoryContent(data).subscribe((response: any) => {
      this.categoryContent = response.data;
      this.contentloading = false;

    });

  }

}
