import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
declare var bootstrap: any;

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrl: './home-banner.component.scss'
})
export class HomeBannerComponent implements OnInit, AfterViewInit {
  @Input() pageType = '';
  @Input() BannerHeading = '';
  @Input() BannerImage = '';
  @ViewChild('bannerCarousel') bannerCarousel: ElementRef<HTMLElement>;
  BannerHeadingfirstPart = '';
  BannerHeadinglastPart = '';
  contentHeight = '';

  @Output() searchEvent = new EventEmitter<string>();

  //  CategoryarrayForListing = ['Young BJS'];
  categories: any[] = [];
  categoryTitles: string[] = [];
  category: string = '';
  categoryTitle: string[] = [];
  CategoryarrayForListing: string[] = [];
  categoryContent: any;
  pageloading = true;
  carouselHeight = 0;
  constructor(
    private articleService: ArticleService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      // this.screenWidth = event.target.window.innerWidth;
      this.carouselHeight = this.bannerCarousel.nativeElement.offsetHeight;
    }

  }

  ngOnInit(): void {

    this.getCategories();
    this.splitText()
    if (this.BannerImage) {
      this.pageloading = false;

    }
  }
ngAfterViewInit(): void {
  if(this.bannerCarousel){
  this.carouselHeight = this.bannerCarousel.nativeElement.offsetHeight;
  const myCarouselElement = this.bannerCarousel.nativeElement;
  const carousel = new bootstrap.Carousel(myCarouselElement, {
    interval: 8000,
    touch: false
  });
}
}
getCategories(): void {
  this.articleService.getAllCategories().subscribe((response: any) => {
      // Assuming "academy" is the identifier for the main "Academy" menu
      const academyMenu = response.find((menu: any) => menu.identifier === 'bjs-academy');

      if (academyMenu) {
        // Filter out "Scientific Surgery" submenu
        const filteredSubMenus = academyMenu.subMenus.filter((subMenu: any) => subMenu.identifier !== 'scientific-surgery');
        
        // Include "Scientific Surgery" submenus
        const scientificSurgeryMenu = academyMenu.subMenus.find((subMenu: any) => subMenu.identifier === 'scientific-surgery');
        
        if (scientificSurgeryMenu) {
          filteredSubMenus.push(...scientificSurgeryMenu.subMenus);
        }

        // Update categories and categoryTitles
        this.categories = filteredSubMenus;

        this.categoryTitles = ['All', ...this.categories.map((subMenu: any) => subMenu.menu_name)];
        
        //this.setCategoryTitle();
      }
    });
  }



  getBackgroundStyle() {
    const style = this.pageType === 'homepage' ? {
      'background-image': 'url("' + this.BannerImage + '")',
      'background-size': 'cover',
      'background-position': 'center',
      'height': '640px'
    } : {
      //  'height': 'auto'
    };


    return style;
  }
  splitText() {
    const words = this.BannerHeading.split(' ');
    if (words.length > 1) {
      this.BannerHeadingfirstPart = words.slice(0, -1).join(' ');
      this.BannerHeadinglastPart = words[words.length - 1];
    } else {
      this.BannerHeadingfirstPart = this.BannerHeading;
      this.BannerHeadinglastPart = '';
    }
  }

  onSearchEvent(searchValue: string) {
    this.searchEvent.emit(searchValue);
  }

}
