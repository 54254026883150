import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild, inject } from '@angular/core';
import { env } from '../../config/config';
import { SharedService } from '../../services/shared.service';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SearchContextService } from '../../services/search-context-service';
import { FilterService } from '../../services/filter.service';


@Component({
	selector: 'app-article-card-list',
	templateUrl: './article-card-list.component.html',
	styleUrl: './article-card-list.component.scss'
})
export class ArticleCardListComponent implements AfterViewInit, OnInit, OnChanges {
	@Input() article: any;
	@Input() index: any;
	@Input() viewFont: string;
	@Input() pageType:any;
	show_more: boolean[] = [];
	offsetHeight: any;
	scrollHeight: any;
	coverImageUrl = '';
	journalid: string = '';
	lastPart: string = '';
	articleLink: string = '';
	safeSummary!: SafeHtml;
	safeTitle!: SafeHtml;
	@ViewChild('content') contentElements: ElementRef<HTMLDivElement> | undefined;
	@ViewChild('maindiv') maindivElement: ElementRef<HTMLDivElement> | undefined;
	private articleReferenceStates = new Map<number, boolean>();

	category: string;
	@Input() searchValue: string;
	@Input() contentType: string[];
	@Input() surgicalSpecialty: string[];
	@Input() pageloading: boolean;
	selectedFilters:any = {
		contentType: [],
		speciality: [],
		category: []
	  };
	  categoryFromUrl: string = '';
	  selectedCategoryId: string | null = null;


	private readonly searchContextService = inject(SearchContextService);

	constructor(private renderer: Renderer2,
		private sharedService: SharedService,
		private route: ActivatedRoute,
		private sanitizer: DomSanitizer,
		private router: Router,
		private filterService: FilterService

	) {
		this.filterService.contentType$.subscribe(filters => {
			this.selectedFilters.contentType = filters;
		  });
	  
		  this.filterService.speciality$.subscribe(filters => {
			this.selectedFilters.speciality = filters;
		  });
	  
		  this.filterService.category$.subscribe(filters => {
			this.selectedFilters.category = filters;
		  });
	  
		  // Initialize filters from the route
		  this.initializeFiltersFromRoute();
		  this.filterService.category$.subscribe(filters => {
			this.selectedCategoryId = filters.includes(this.selectedCategoryId) ? this.selectedCategoryId : null;
		  });
	}

	ngOnInit() {
		// this.route.queryParams.subscribe(params => {
		// 	this.categoryFromUrl = params['category'] || ''; // Extract category from URL
		//   });


	  this.route.queryParams.subscribe(params => {
		this.categoryFromUrl = params['category'] || ''; // Extract category from URL
	  });

		this.journalid = this.sharedService.getJournalId();
		// if (this.route.url) {
		// 	this.route.url.subscribe(urlSegments => {
		// 		if (urlSegments && urlSegments.length > 0) {
		// 			const lastSegment = urlSegments[urlSegments.length - 1];
		// 			if (lastSegment && lastSegment.path) {
		// 				this.lastPart = lastSegment.path;
		// 			}
		// 		}
		// 	});
		//   } 
		if (this.route.url) {
			this.route.url.subscribe(urlSegments => {
		 
			  if (urlSegments && urlSegments.length > 0) {
				const firstSegment = urlSegments[0]; // Get the first segment
	  
				if (firstSegment && firstSegment.path) {
				  this.category = firstSegment.path; 
				}
			  }
			  else{
				// const firstSegment = urlSegments[0]; // Get the first segment
				// if (firstSegment && firstSegment.path) {
				//   this.category = firstSegment.path; 
				// }
			  }
			});
		}

		if (this.article) {
			this.pageloading = false;
			this.safeSummary = this.sanitizer.bypassSecurityTrustHtml(this.article.article_summary);
			// this.safeTitle = this.sanitizer.bypassSecurityTrustHtml(this.article.article_title);
			if (this.searchValue && this.article.article_title.includes(this.searchValue)) {
				const highlightedTitle = this.highlightSearchValue(this.article.article_title, this.searchValue);
				this.safeTitle = this.sanitizer.bypassSecurityTrustHtml(highlightedTitle);
			} else {
				const highlightedTitle = this.highlightSearchValue(this.article.article_title, this.searchValue);

				this.safeTitle = this.sanitizer.bypassSecurityTrustHtml(highlightedTitle);
			}

			if (this.lastPart) {
				this.coverImageUrl = `${env.articleCoverImage}${encodeURIComponent(this.journalid)}/${encodeURIComponent(this.article.category_heading.id)}/${encodeURIComponent(this.article.article_id)}/${encodeURIComponent(this.article.article_cover_image)}`;
				this.articleLink = this.article.article_link;
			} else {
				this.coverImageUrl = `${env.articleCoverImage}${encodeURIComponent(this.journalid)}/${encodeURIComponent(this.article.category_heading.id)}/${encodeURIComponent(this.article.article_id)}/${encodeURIComponent(this.article.article_cover_image)}`;
				this.articleLink = `/bjs-academy/${encodeURIComponent(this.article.category_heading.id)}/${this.article.article_link}`;
			}
		}
		this.route.queryParams.subscribe(queryParams => {
			
			if (queryParams['speciality']) {
			  const filterValue = queryParams['speciality'].split(',').map((filter: string) => filter.trim());
			  this.searchContextService.setSelectedSpecialities(filterValue);
			}
			
			if (queryParams['contentType']) {
			  const contentType = queryParams['contentType'].split(',').map((filter: string) => filter.trim());
			  this.searchContextService.setSelectedContentType(contentType);
			}
		  
			// Continue with your data fetching logic
			//this.filterDataApi(this.journalid);
		  });

	}

	highlightSearchValue(title: string, searchValue: string): string {
		if (searchValue) {
		  const normalizedSearchValue = searchValue.toLowerCase();
		  const regex = new RegExp(normalizedSearchValue, 'gi');
		  
		  // Replace matches with highlighted span
		  return title.replace(regex, `<span style="background-color: yellow">$&</span>`);
		}
		return title;
	  }
	  
	  private resetSearchContext(): void {
		this.searchContextService.setSelectedSpecialities([]);
		this.searchContextService.setSelectedContentType([]);
		this.searchContextService.setSearchContent(undefined);
	}

	ngAfterViewInit() {
		this.scrollHeight = this.contentElements?.nativeElement.scrollHeight;
		this.offsetHeight = this.contentElements?.nativeElement.offsetHeight;
		if (this.scrollHeight > this.offsetHeight) {
			this.renderer.setStyle(this.maindivElement?.nativeElement.querySelector("[class='txtcol']"), 'display', 'contents');
			this.maindivElement?.nativeElement.classList.add('truncate');
		}
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (this.maindivElement && this.maindivElement.nativeElement) {
			if (this.viewFont == 'dyslexic') {
				this.maindivElement.nativeElement.classList.add('dyslexic-regular');
			} else if (this.viewFont == 'normal') {
				if (this.maindivElement.nativeElement.classList.contains('dyslexic-regular')) {
					this.maindivElement.nativeElement.classList.remove('dyslexic-regular');
				}
			}
		}
	}

	toggleShowMore(maindiv: HTMLDivElement, event: Event) {
		const toggleButton = event.target as HTMLElement;
		let card_div = maindiv.querySelector("[class='card-tag']");
		if (maindiv.classList.contains('truncate')) {
			card_div.parentElement.parentElement.classList.remove("flex-row");
			card_div.parentElement.parentElement.classList.add("flex-column")
			this.scrollHeight = maindiv.querySelector("[class='card-tag']")?.scrollHeight;
			this.renderer.setStyle(card_div, 'max-height', `${this.scrollHeight}px`);
			toggleButton.innerHTML = 'Less...'
			this.maindivElement?.nativeElement.classList.remove('truncate');
		}
		else {
			this.renderer.setStyle(card_div, 'max-height', '36px');
			toggleButton.innerHTML = 'More...';
			card_div.parentElement.parentElement.classList.remove("flex-column");
			card_div.parentElement.parentElement.classList.add("flex-row")
			this.maindivElement?.nativeElement.classList.add('truncate');
		}
	}
	isStringArray(authors: any[]): boolean {
		return typeof authors[0] === 'string';
	}

	isObjectArray(authors: any[]): boolean {
		return typeof authors[0] === 'object';
	}
	formatDate(preprint: any): string {
		if (!preprint || !preprint.day || !preprint.month || !preprint.year) {
			return '';
		}

		const months = [
			'January', 'February', 'March', 'April', 'May', 'June',
			'July', 'August', 'September', 'October', 'November', 'December'
		];

		const day = preprint.day;
		const month = months[preprint.month - 1];
		const year = preprint.year;

		return `${day} ${month} ${year}`;
	}
	navigateToArticle(article_link: any,category_heading:any) {
	
		if(category_heading){
		  this.router.navigate(['/bjs-academy', category_heading,article_link]).then(navigated => {
	
			if (!navigated) {
			  // Handle failed navigation
			}
		  });
		}
	
	}
	public highlightedContent: string;


	highlightReference(tag: string): SafeHtml {
		// Check if surgicalSpecialty is defined and is an array
		if (!this.surgicalSpecialty || !Array.isArray(this.surgicalSpecialty)) {
		  return this.sanitizer.bypassSecurityTrustHtml(tag);
		}
	  
		// Perform highlighting for each name in surgicalSpecialty
		for (const name of this.surgicalSpecialty) {
		  const regex = new RegExp('\\b' + name + '\\b', 'gi');
		  tag = tag.replace(regex, `<span style="background-color: yellow;
			border-radius: 5px;
			color:black;
			padding: 0px 5px;
			border-style: solid;
			border-color: rgb(85, 85, 221);
			border-width: 1px;
			display: inline-block;">$&</span>`);
		}
	  
		return this.sanitizer.bypassSecurityTrustHtml(tag);
	  }




highlightReferenceHeading(tag: string): SafeHtml {
    // Ensure that the article and category_tags are defined
    if (!this.article || !Array.isArray(this.article.category_tags) || !tag) {
      return this.sanitizer.bypassSecurityTrustHtml(tag);
    }
    if (this.categoryFromUrl) {
    	this.article.category_tags.forEach((categoryTag: any) => {
			const regex = new RegExp('\\b' + categoryTag + '\\b', 'gi');
			tag = tag.replace(regex, `<span style="background-color: yellow;
				border-radius: 5px;
				color: black;
				padding: 0px 5px;
				border-style: solid;
				border-color: rgb(85, 85, 221);
				border-width: 1px;
				display: inline-block;">$&</span>`);
		});
    }

    // Bypass Angular's security to safely inject the HTML
    return this.sanitizer.bypassSecurityTrustHtml(tag);
  }



	
	highlightType(type: string): SafeHtml {
		if (!this.contentType || !Array.isArray(this.contentType)) {
		  return this.sanitizer.bypassSecurityTrustHtml(type);
		}
	  
		// const isHighlighted = Array.isArray(this.contentType) && this.contentType.includes(type);
		// const className = isHighlighted ? 'highlight' : '';
		// const html = `<span class="${className}">${type}</span>`;

		// Perform highlighting for each name in surgicalSpecialty
		for (const name of this.contentType) {
			const regex = new RegExp('\\b' + name + '\\b', 'gi');
			type = type.replace(regex, `<span style="background-color: yellow;
			  border-radius: 5px;
			  color:black;
			  padding: 0px 5px;
			  border-style: solid;
			  border-color: rgb(85, 85, 221);
			  border-width: 1px;
			  display: inline-block;">$&</span>`);
		  }
		return this.sanitizer.bypassSecurityTrustHtml(type);
	}

	returnCategory(category:string){
		if(category == 'social-media' ||category == 'randomized-clinical-trials'||category == 'surgical-science' || category == 'surgery-for-all'){
			return true;
		}
		else{
			return false;
		}
	}
	categoryClick(value: any, title: any) {
		this.toggleFilter(this.selectedFilters.category, value);
		this.updateQueryParams();
	}
// 	categoryClick(id: string, title: string) {
// 		this.selectedCategoryId = this.selectedCategoryId === id ? null : id; // Toggle highlight
// 	    this.toggleFilter(this.selectedFilters.category, id);
// 	 	this.updateQueryParams();

// }
	
	filterClicked(value: string, filterType: string, scientific_surgery: string = '') {
		if (filterType === 'type') {
		  this.toggleFilter(this.selectedFilters.contentType, value);
		  this.filterService.setContentType(this.selectedFilters.contentType);
		} else if (filterType === 'speciality') {
		  this.toggleFilter(this.selectedFilters.speciality, value);
		  this.filterService.setSpeciality(this.selectedFilters.speciality);
		} else if (filterType === 'category') {
		  this.toggleFilter(this.selectedFilters.category, value);
		  this.filterService.setCategory(this.selectedFilters.category);
		}
	
		this.updateQueryParams(scientific_surgery);
	  }
	
	  toggleFilter(filterArray: string[], value: string) {
		
		const index = filterArray.indexOf(value);
		if (index === -1) {
		  filterArray.push(value);  // Add the value if it doesn't exist
		} else {
		  filterArray.splice(index, 1);  // Remove the value if it exists
		}
	  }
	
	  updateQueryParams(scientific_surgery: string = '') {
		const currentQueryParams = { ...this.route.snapshot.queryParams };
	
		if (this.selectedFilters.contentType.length) {
		  currentQueryParams['contentType'] = this.selectedFilters.contentType.join(',');
		} else {
		  delete currentQueryParams['contentType'];
		}
	
		if (this.selectedFilters.speciality.length) {
		  currentQueryParams['speciality'] = this.selectedFilters.speciality.join(',');
		} else {
		  delete currentQueryParams['speciality'];
		}
	
		if (this.selectedFilters.category.length) {
		  currentQueryParams['category'] = this.selectedFilters.category.join(',');
		} else {
		  delete currentQueryParams['category'];
		}
	
		if (this.searchValue) {
		  currentQueryParams['search'] = this.searchValue;
		} else {
		  delete currentQueryParams['search'];
		}
	
		let path: string[];
		if (this.pageType === undefined || this.pageType === "allpage") {
		  path = ['/bjs-academy', 'all-articles', 'page', '1'];
		} else if (this.pageType === "listpage" && ['social-media', 'randomized-clinical-trials', 'surgical-science', 'surgery-for-all'].includes(scientific_surgery)) {
		  path = ['/bjs-academy', scientific_surgery, this.category, 'page', '1'];
		} else if (this.pageType === "listpage") {
		  path = ['/bjs-academy', this.category, 'page', '1'];
		} else {
		  path = ['/bjs-academy', 'all-articles', 'page', '1'];
		}
	
		this.router.navigate(path, {
		  queryParams: currentQueryParams,
		  queryParamsHandling: 'merge'
		}).then(() => {
		}).catch(err => {
		});
	  }
	
	  private initializeFiltersFromRoute() {
		// Initialize filters from the current route query parameters
		const queryParams = this.route.snapshot.queryParams;
		if (queryParams['contentType']) {
		  this.selectedFilters.contentType = queryParams['contentType'].split(',');
		  this.filterService.setContentType(this.selectedFilters.contentType);
		}
		if (queryParams['speciality']) {
		  this.selectedFilters.speciality = queryParams['speciality'].split(',');
		  this.filterService.setSpeciality(this.selectedFilters.speciality);
		}
		if (queryParams['category']) {
		  this.selectedFilters.category = queryParams['category'].split(',');
		  this.filterService.setCategory(this.selectedFilters.category);
		}
	  }
	
	}


