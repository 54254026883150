import { Component, EventEmitter, Input, OnChanges, SimpleChanges, Output, PLATFORM_ID, Inject, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ArticleService } from '../../services/article.service';
import { SearchContextService } from '../../services/search-context-service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnChanges {
  @Input() categoryArray: string[] = [];
  @Input() pageType = '';
  // @Input() articles: any;

  @Input() contentType: any;
  @Input() surgicalSpecialty: any;

  selectedCat: string = 'All';
  screenWidth = 0;
  category: string = '';
  categoryContent: any[] = [];
  homepage: string = 'homepage';
  listpage: string = 'listpage';
  allpage: string = 'allpage';
  @Output() searchEvent = new EventEmitter<string>();
  @Output() categoryValue = new EventEmitter<string>();


  categoryTitles: any[] = [];
  selectedCatheading = 'All';
  pageloading = true;
  tabarray = new Array(8).fill(0);
  private readonly searchContextService = inject(SearchContextService);

  //categoryTitles: string[] = ['All', ...this.categoryArray.map((subMenu: any) => subMenu.menu_name)];

  constructor(@Inject(PLATFORM_ID) private platformId: any, private route: ActivatedRoute, private articleService: ArticleService,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
    }
  }


  ngOnInit(): void {

    this.updateCategoryTitles();
    this.route.params.subscribe(params => {

      if (params['subcategory']) {
        this.category = params['subcategory'];
      } else {
        this.category = params['category'];
      }

    });
    if (this.pageType === 'homepage') {
      this.selectedCat = 'All';

    } if (this.pageType === 'allpage') {
      this.selectedCat = 'All';
      this.category = "all-page";
      if (this.categoryArray) {
        this.categoryTitles = this.categoryArray;
        
      }
    }
    else {
      this.getCategoryContent(this.category);
      this.selectedCat = this.category;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryArray'] && !changes['categoryArray'].isFirstChange()) {
      // Called when categoryArray input changes
      if (this.pageType == 'allpage' && this.categoryArray.length > 0) {
        this.pageloading = false
      }
      this.updateCategoryTitles();
      //this.resetSearchContext();
    }

  }

  updateCategoryTitles(): void {

    if (this.categoryArray) {
      this.categoryTitles = ['All', ...this.categoryArray.map((subMenu: any) => subMenu.menu_name)];

    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (isPlatformBrowser(this.platformId)) this.screenWidth = window.innerWidth;
  }

  // selectedCategory(heading: string) {
  //   this.selectedCatheading = heading;
  //   if (heading === 'All') {
  //     // Handle the "All" case
  //     this.selectedCatheading = heading;
  //     this.selectedCat = 'All'; // Clear the selected category or set to a default value
  //             this.categoryValue.emit(this.selectedCat)

  //    // this.searchEvent.emit(this.selectedCat);
  //   } else {
  //     //console.log(this.categoryContent);

  //     // Find the object in the array that matches the given heading
  //     const selectedCategoryObj: any = this.categoryContent.find((category: any) => category.heading === heading);

  //     if (selectedCategoryObj) {
  //       // Replace underscores with hyphens in the menu value
  //       const formattedCategory = selectedCategoryObj.menu.replace(/_/g, '-');
  //      // this.selectedCatheading = selectedCategoryObj.menu;
  //       // Set the selected category and pass it to the search component
  //       this.selectedCat = formattedCategory;
  //       this.category = this.selectedCat;
  //       this.categoryValue.emit(this.selectedCat)
  //       this.selectedCatheading=heading;
  //     //  console.log(searchValue); // For debugging
  //     } else {
  //       console.error('Category object not found:', selectedCategoryObj);
  //     }
  //   }
  // }

  selectedCategory(heading: string) {
    if (this.selectedCatheading !== heading) {
      this.selectedCatheading = heading;
      if (heading === 'All') {
        this.selectedCat = 'All';
        this.categoryValue.emit(this.selectedCat);
      } else {
        const data = {
          category: heading
        }
        this.articleService.getCategoryData(data).subscribe((response: any) => {
          this.selectedCat = response.data[0].menu;
          this.categoryValue.emit(this.selectedCat);
          // this.resetSearchContext();

        });
        // const selectedCategoryObj: any = this.categoryContent.find((category: any) => category.heading === heading);
        // if (selectedCategoryObj) {
        //   const formattedCategory = selectedCategoryObj.menu.replace(/_/g, '-');
        //   this.selectedCat = formattedCategory;
        //   this.category = this.selectedCat;
        //   this.categoryValue.emit(this.selectedCat);
        // } else {
        // }
      }
    }
  }

  onSearchEvent(searchValue: string) {
    this.searchContextService.setSearchContent(searchValue);
    this.searchEvent.emit(searchValue);
  }
  getCategoryContent(category: string) {
    const data = {
      category: category
    }
    this.articleService.getCategoryContent(data).subscribe((response: any) => {
      this.categoryContent = response.data;
      this.pageloading = false;

    });

  }
  trackByCategory(index: number, category: any): string {
    return category; // or return category.identifier if you have a unique identifier
  }
  private resetSearchContext(): void {
    this.searchContextService.setSelectedSpecialities([]);
    this.searchContextService.setSelectedContentType([]);
    this.searchContextService.setSearchContent(undefined);
  }

}
